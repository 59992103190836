"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/local-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.gameTableManageSearch = gameTableManageSearch;
exports.gameTableManageCreate = gameTableManageCreate;
exports.gameTableManageEdit = gameTableManageEdit;
exports.gameTableManageUpdate = gameTableManageUpdate;
exports.tableStartOrStop = tableStartOrStop;
exports.tableOpen = tableOpen;
exports.gameTableManageList = gameTableManageList;
exports.batchTableList = batchTableList;
exports.batchTableMaintain = batchTableMaintain;
exports.statisticInformation = statisticInformation;
exports.dropDownList = dropDownList;
exports.selectTableAndLimitListInfo = selectTableAndLimitListInfo;
exports.default = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 桌台管理 - 列表查询
 * @param {
 * id: number,
 * name: string,
 * page: number,
 * pageSize: number
 * }
 */
function gameTableManageSearch(params) {
  return (0, _request.default)({
    url: '/gameManage/gameTableManage/search',
    method: 'get',
    params: params
  });
}
/**
 * 桌台管理 - 新建
 * @param {
 * "dealerDataSources": "string",
 * "gameTypeId": 0,
 * "gameTypeName": "string",
 * "name": "string",
 * "oneselfBalanceLimit": 0,
 * "physicsTableNo": "string",
 * "seatBalanceLimit": 0,
 * "shareBalanceLimit": 0,
 * "tableId": "string",
 * "type": 0,
 * "videoUrl": "string"
 *  }
 */


function gameTableManageCreate(data) {
  return (0, _request.default)({
    url: '/gameManage/gameTableManage/create',
    method: 'post',
    data: data
  });
}
/**
 * 桌台管理 - 编辑
 * @param {
 *  id: number
 * }
 */


function gameTableManageEdit(data) {
  return (0, _request.default)({
    url: '/gameManage/gameTableManage/edit',
    method: 'post',
    data: data
  });
}
/**
 * 桌台管理-修改-确定按钮
 */


function gameTableManageUpdate(data) {
  return (0, _request.default)({
    url: '/gameManage/gameTableManage/update',
    method: 'post',
    data: data
  });
}
/**
 * 桌台管理-启用/停用
 * @param {
 * id: number
 *  }
 */


function tableStartOrStop(data) {
  return (0, _request.default)({
    url: '/gameManage/gameTableManage/tableStartOrStop',
    method: 'post',
    data: data
  });
} // 游戏桌台开放


function tableOpen(data) {
  return (0, _request.default)({
    url: '/gameManage/gameTableManage/tableOpen',
    method: 'post',
    data: data
  });
}
/**
 * 桌台管理-获取游戏桌台
 */


function gameTableManageList(params) {
  return (0, _request.default)({
    url: '/gameManage/gameTableManage/list',
    method: 'get',
    params: params
  });
}
/**
 * 桌台管理-批量获取游戏桌台
 */


function batchTableList(params) {
  return (0, _request.default)({
    url: '/gameManage/gameTableManage/batchTableList',
    method: 'get',
    params: params
  });
} // 批量桌台维护


function batchTableMaintain(data) {
  return (0, _request.default)({
    url: '/gameManage/gameTableManage/batchTableMaintain',
    method: 'post',
    data: data
  });
} // 全闭维护桌台统计/启动桌台统计


function statisticInformation(params) {
  return (0, _request.default)({
    url: '/gameManage/gameTableManage/statisticInformation',
    method: 'get',
    params: params
  });
} // 获取所有下拉框


function dropDownList(params) {
  return (0, _request.default)({
    url: 'gameManage/dropDown/list',
    method: 'get',
    params: params
  });
} // 桌台限红查询


function selectTableAndLimitListInfo(params) {
  return (0, _request.default)({
    url: '/gameManage/betLimitManage/selectTableAndLimitListInfo',
    method: 'get',
    params: params
  });
}

var _default = {
  selectTableAndLimitListInfo: selectTableAndLimitListInfo,
  gameTableManageSearch: gameTableManageSearch,
  gameTableManageCreate: gameTableManageCreate,
  gameTableManageEdit: gameTableManageEdit,
  gameTableManageUpdate: gameTableManageUpdate,
  gameTableManageList: gameTableManageList,
  dropDownList: dropDownList,
  tableStartOrStop: tableStartOrStop,
  tableOpen: tableOpen,
  batchTableList: batchTableList,
  batchTableMaintain: batchTableMaintain,
  statisticInformation: statisticInformation
};
exports.default = _default;