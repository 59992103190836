"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/local-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _elementUi = require("element-ui");

var _jsCookie = _interopRequireDefault(require("js-cookie"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { validUsername } from '@/utils/validate'
var devLoginForm = {
  username: 'game-admin',
  password: '123456',
  googleAuth: ''
};
var _default = {
  name: 'Login',
  data: function data() {
    return {
      loginForm: this.env === 'dev' ? devLoginForm : {},
      loading: false,
      passwordType: 'password',
      redirect: undefined,
      timestamp: +new Date(),
      version: '',
      baseUrl: process.env.VUE_APP_BASE_API,
      verifyCodeType: 0,
      moonShield: null
    };
  },
  computed: {
    language: function language() {
      return this.$store.getters.language;
    },
    loginRules: function loginRules() {
      var _this = this;

      var validatePassword = function validatePassword(rule, value, callback) {
        if (value.length < 6) {
          callback(new Error(_this.$t('login.less6Password')));
        } else {
          callback();
        }
      };

      return {
        username: [{
          required: true,
          trigger: 'blur',
          // validator: validateUsername,
          message: this.$t('login.username')
        }],
        password: [{
          required: true,
          message: this.$t('login.password')
        }, {
          trigger: 'blur',
          validator: validatePassword
        }],
        googleAuth: [{
          required: true,
          trigger: 'blur',
          message: this.verifyCodeType === 1 ? this.$t('login.codeVerify') : '请输入动态密码'
        }]
      };
    }
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  mounted: function mounted() {
    var VERSION = _jsCookie.default.get('version') || '';
    this.version = VERSION;
  },
  methods: {
    getBaseUrl: function getBaseUrl(api) {
      var url = new URL(api);
      return url.origin;
    },
    handleChange: function handleChange(val) {
      var _this2 = this;

      this.$api.getVerifyCodeTypeandErrorLogin({
        username: val
      }).then(function (res) {
        _this2.verifyCodeType = res.data.userInfo.verifyCodeType;
        _this2.moonShield = res.data.userInfo.moonShield;
      });
    },
    // 兼容ie input 失去 再 聚焦 光标从头开始
    fixInput: function fixInput(obj) {
      var nodes = obj.$el.childNodes[1];
      nodes.focus(); // 解决ff不获取焦点无法定位问题

      if (window.getSelection) {
        // ie11 10 9 ff safari
        var max_Len = nodes.value.length; // text字符数

        nodes.setSelectionRange(max_Len, max_Len);
      } else if (document.selection) {
        // ie10 9 8 7 6 5
        var range = nodes.createTextRange(); // 创建range

        range.collapse(false); // 光标移至最后

        range.select(); // 避免产生空格
      }
    },
    handleSetLanguage: function handleSetLanguage(lang) {
      _elementUi.Message.closeAll();

      var actMessage;

      switch (lang) {
        case 'zh':
          actMessage = '切换语言成功';
          break;

        case 'tw':
          actMessage = '切換語言成功';
          break;

        default:
          actMessage = 'Switch Language Success';
      }

      this.$i18n.locale = lang;
      this.$store.dispatch('app/setLanguage', lang);
      this.$message({
        message: actMessage,
        type: 'success'
      });
    },
    showPwd: function showPwd() {
      var _this3 = this;

      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }

      this.$nextTick(function () {
        _this3.fixInput(_this3.$refs.password);
      });
    },
    handleLogin: function handleLogin() {
      var _this4 = this;

      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this4.loading = true;

          _this4.$store.dispatch('user/login', _this4.loginForm).then(function () {
            _this4.$router.push({
              path: '/'
            });

            _this4.loading = false;
          }).catch(function () {
            _this4.timestamp = +new Date();
            _this4.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
exports.default = _default;