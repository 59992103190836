"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/local-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseTime = parseTime;
exports.formatTime = formatTime;
exports.param2Obj = param2Obj;
exports.formatCurrency = formatCurrency;
exports.validateExcel = validateExcel;
exports.checkNullObj = checkNullObj;
exports.IsURL = IsURL;
exports.isIp = isIp;
exports.switchSecond = switchSecond;
exports.dzpResultTran = dzpResultTran;
exports.tran6 = tran6;
exports.msgExc = msgExc;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _slicedToArray2 = _interopRequireDefault(require("/root/.jenkins/workspace/local-vue-admin-game.build/node_modules/@babel/runtime-corejs2/helpers/slicedToArray"));

require("core-js/modules/es6.number.constructor");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.regexp.to-string");

var _typeof2 = _interopRequireDefault(require("/root/.jenkins/workspace/local-vue-admin-game.build/node_modules/@babel/runtime-corejs2/helpers/typeof"));

var _store = _interopRequireDefault(require("@/store"));

/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }

  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;

  if ((0, _typeof2.default)(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }

    date = new Date(time);
  }

  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, function (result, key) {
    var value = formatObj[key]; // Note: getDay() returns 0 on Sunday

    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }

    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }

    return value || 0;
  });
  return time_str;
}
/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */


function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }

  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;

  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }

  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}
/**
 * @param {string} url
 * @returns {Object}
 */


function param2Obj(url) {
  var search = url.split('?')[1];

  if (!search) {
    return {};
  }

  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
}
/**
 * 格式化金钱数字
 * @param {number} num
 */


function formatCurrency(num) {
  if (!num) {
    return '';
  }

  num = num.toString().replace(/\$|\,/g, '');
  if (isNaN(num)) num = '0';
  var sign = +num === (num = Math.abs(num));
  num = Math.floor(num * 100 + 0.50000000001);
  var cents = num % 100;
  num = Math.floor(num / 100).toString();
  if (cents < 10) cents = '0' + cents;

  for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++) {
    num = num.substring(0, num.length - (4 * i + 3)) + ',' + num.substring(num.length - (4 * i + 3));
  }

  return (sign ? '' : '-') + num + '.' + cents;
}
/**
 * excel格式校验
 * @param {file:file} file
 */


function validateExcel(file) {
  if (!/\.(csv|xlsx|xls|XLSX|XLS)$/.test(file.name)) {
    return false;
  }

  return true;
}
/**
 * @desc 判断一个对象是否为空
 * @param {Object} obj 对象
 * @return {Boolean}
 */


function checkNullObj(obj) {
  return Object.keys(obj).length === 0;
}
/**
 * 判断输入的url是否正确
 * @param {String} str_url
 */


function IsURL(str_url) {
  return /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?/.test(str_url);
}
/**
 * 判断ip是否正确
 * @param {String} value
 */


function isIp(value) {
  return /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])(\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])){3}$/.test(value);
}
/**
 * @desc 秒与毫秒转换
 * @param {} value 原始值
 * @param {Number} type 1为 秒转毫秒 2为毫秒转秒
 */


function switchSecond(value, type) {
  if (!value || !/^\d+$/.test(value)) return;

  if (type === 1) {
    return Number(value) * 1000;
  } else {
    return Number(value) / 1000;
  }
} // 大转盘数据转化


function dzpResultTran(inStr) {
  // 原始的 str 格式 2:4:4;5
  var _inStr$split = inStr.split(';'),
      _inStr$split2 = (0, _slicedToArray2.default)(_inStr$split, 2),
      beishuStr = _inStr$split2[0],
      r = _inStr$split2[1];

  if (!r) {
    return inStr;
  }

  var beishuArr = beishuStr.split(':');
  var sum = 1;
  beishuArr.forEach(function (i) {
    sum *= Number(i);
  });
  return "".concat(r, "(").concat(sum, "x)");
} // 超级六数据转化


function tran6(inStr) {
  if (!inStr) {
    return;
  }

  if (typeof inStr !== 'string') {
    return inStr;
  }

  if (inStr.includes('超级六')) {
    return '幸运六';
  }

  if (inStr.includes('超級六')) {
    return '幸運六';
  }

  return inStr;
}
/**
 *  导出excel提示 统一
 */


function msgExc(str, _this) {
  if (typeof str !== 'string') {
    return false;
  }

  if (!str.includes('下载中心')) {
    return false;
  }

  console.log({
    str: str,
    _this: _this
  });
  var h = _this.$createElement;

  _this.$msgbox({
    title: _this.$t('btn.tip'),
    message: h('p', null, [h('span', null, _this.$t('download.downTip')), h('p', {
      style: 'color: #409EFF;cursor: pointer;',
      on: {
        click: function click() {
          _this.$msgbox.close();

          _store.default.commit('app/SET_DOWNLOAD', true);
        }
      }
    }, _this.$t('download.taskSee'))]),
    showClose: true,
    showCancelButton: false,
    confirmButtonText: _this.$t('tableColumn.shutDown')
  });

  return true;
}