"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/local-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Download = _interopRequireDefault(require("@/components/Download.vue"));

//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'AppMain',
  components: {
    Download: _Download.default
  },
  computed: {
    key: function key() {
      return this.$route.path;
    }
  }
};
exports.default = _default;