"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/local-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tenantSearch = tenantSearch;
exports.updateOrSave = updateOrSave;
exports.fetchAuthTenantList = fetchAuthTenantList;
exports.updateTenantStatus = updateTenantStatus;
exports.updateAuthTenant = updateAuthTenant;
exports.updateAuthTenantStatus = updateAuthTenantStatus;
exports.deleteTenant = deleteTenant;
exports.getDropDownList = getDropDownList;
exports.tenantDeleteAuth = tenantDeleteAuth;
exports.getHallIdByTable = getHallIdByTable;
exports.default = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 查询租户列表
 * @param {
 * "tenantKey": 0,
 * "startAt": '',
 * "endAt": '',
 * "pageIndex": 0,
 * "pageSize": 0
 * }
 */
function tenantSearch(params) {
  return (0, _request.default)({
    url: '/tenant/search',
    method: 'get',
    params: params
  });
}

function updateOrSave(data) {
  return (0, _request.default)({
    url: '/tenant/updateOrSave',
    method: 'post',
    data: data
  });
}

function fetchAuthTenantList(params) {
  return (0, _request.default)({
    url: '/tenant/list/auth',
    method: 'get',
    params: params
  });
}

function updateTenantStatus(data) {
  return (0, _request.default)({
    url: '/tenant/update/status',
    method: 'post',
    data: data
  });
}

function updateAuthTenant(data) {
  return (0, _request.default)({
    url: '/tenant/update/auth',
    method: 'post',
    data: data
  });
}

function updateAuthTenantStatus(data) {
  return (0, _request.default)({
    url: '/tenant/update/auth/status',
    method: 'post',
    data: data
  });
}

function deleteTenant(data) {
  return (0, _request.default)({
    url: '/tenant/delete',
    method: 'post',
    data: data
  });
}

function getDropDownList(params) {
  return (0, _request.default)({
    url: '/gameManage/dropDown/tenant/list',
    method: 'get',
    params: params
  });
}

function tenantDeleteAuth(data) {
  return (0, _request.default)({
    url: '/tenant/delete/auth',
    method: 'post',
    data: data
  });
}

function getHallIdByTable(params) {
  return (0, _request.default)({
    url: '/gameManage/dropDown/tenant/table/list',
    method: 'get',
    params: params
  });
}

var _default = {
  tenantSearch: tenantSearch,
  updateOrSave: updateOrSave,
  fetchAuthTenantList: fetchAuthTenantList,
  updateTenantStatus: updateTenantStatus,
  updateAuthTenant: updateAuthTenant,
  updateAuthTenantStatus: updateAuthTenantStatus,
  deleteTenant: deleteTenant,
  getDropDownList: getDropDownList,
  tenantDeleteAuth: tenantDeleteAuth,
  getHallIdByTable: getHallIdByTable
};
exports.default = _default;