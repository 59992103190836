"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/local-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/.jenkins/workspace/local-vue-admin-game.build/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _system = _interopRequireDefault(require("./system"));

var _abnormal = _interopRequireDefault(require("./abnormal"));

var _user = _interopRequireDefault(require("./user"));

var _game = _interopRequireDefault(require("./game"));

var _gameTable = _interopRequireDefault(require("./gameTable"));

var _gameType = _interopRequireDefault(require("./gameType"));

var _gameModule = _interopRequireDefault(require("./gameModule"));

var _betPoint = _interopRequireDefault(require("./betPoint"));

var _limitRed = _interopRequireDefault(require("./limitRed"));

var _role = _interopRequireDefault(require("./role"));

var _notice = _interopRequireDefault(require("./notice"));

var _account = _interopRequireDefault(require("./account"));

var _dealer = _interopRequireDefault(require("./dealer"));

var _label = _interopRequireDefault(require("./label"));

var _ipblacklist = _interopRequireDefault(require("./ipblacklist"));

var _parameterConfig = _interopRequireDefault(require("./parameterConfig"));

var _apicontrol = _interopRequireDefault(require("./apicontrol"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _default = _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, _system.default), _user.default), _game.default), _gameTable.default), _gameType.default), _gameModule.default), _betPoint.default), _limitRed.default), _role.default), _abnormal.default), _notice.default), _account.default), _dealer.default), _label.default), _parameterConfig.default), _ipblacklist.default), _apicontrol.default);

exports.default = _default;