"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/local-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.noticeManageCreate = noticeManageCreate;
exports.noticeManageSearch = noticeManageSearch;
exports.noticeManageStatusUpdate = noticeManageStatusUpdate;
exports.noticeManageUpdate = noticeManageUpdate;
exports.default = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 公告管理 - 创建
 * @param {
 * "publishEndTime": "2019-08-12T03:31:05.127Z",
 * "publishStartTime": "2019-08-12T03:31:05.127Z",
 * "content": "string",
 * "title": "string",
 * "type": 0
 * }
 */
function noticeManageCreate(data) {
  return (0, _request.default)({
    url: '/systemManage/noticeManage/create',
    method: 'post',
    data: data
  });
}
/**
 * 公告管理 - 查询
 */


function noticeManageSearch(params) {
  return (0, _request.default)({
    url: '/systemManage/noticeManage/search',
    method: 'get',
    params: params
  });
}
/**
 * 公告管理 - 启用/停用
 * @param {
 * id: number
 * status: number
 * }
 */


function noticeManageStatusUpdate(data) {
  return (0, _request.default)({
    url: '/systemManage/noticeManage/updateStatus',
    method: 'post',
    data: data
  });
}
/**
 * 公告管理 - 修改
 */


function noticeManageUpdate(data) {
  return (0, _request.default)({
    url: '/systemManage/noticeManage/update',
    method: 'post',
    data: data
  });
}

var _default = {
  noticeManageCreate: noticeManageCreate,
  noticeManageSearch: noticeManageSearch,
  noticeManageStatusUpdate: noticeManageStatusUpdate,
  noticeManageUpdate: noticeManageUpdate
};
exports.default = _default;