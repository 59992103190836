"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/local-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = _interopRequireDefault(require("@/api"));

var install = function install(Vue) {
  if (install.installed) return;
  install.installed = true;
  Object.defineProperties(Vue.prototype, {
    // 注意哦，此处挂载在 Vue 原型的 $api 对象上
    $api: {
      get: function get() {
        return _api.default;
      }
    }
  });
};

var _default = install;
exports.default = _default;