"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/local-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getToken = getToken;
exports.setToken = setToken;
exports.removeToken = removeToken;
exports.getUserName = getUserName;
exports.getUserInfo = getUserInfo;
exports.setUserInfo = setUserInfo;
exports.removeUserInfo = removeUserInfo;

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _storejs = _interopRequireDefault(require("storejs"));

var TokenKey = 'zr-token-key';
var UserInfoKey = 'zr-user-key';

function getToken() {
  return _jsCookie.default.get(TokenKey);
}

function setToken(token) {
  return _jsCookie.default.set(TokenKey, token);
}

function removeToken() {
  return _jsCookie.default.remove(TokenKey);
}

function getUserName() {
  var userInfo = _storejs.default.get(UserInfoKey) || {};
  return userInfo.username;
}

function getUserInfo() {
  var userInfo = _storejs.default.get(UserInfoKey) || null;
  return userInfo;
}

function setUserInfo(UserInfo) {
  return _storejs.default.set(UserInfoKey, UserInfo);
}

function removeUserInfo() {
  return _storejs.default.remove(UserInfoKey);
}